import config from "@/config";
import request from "@/utils/request";

/**
 * 获取验证码
 * @returns
 */
export function apiGetCaptcha() {
  return request.get("/passport/captcha");
}

/**
 * 账号登录
 * @param {*} params
 * @returns
 */
export function apiLogin(params) {
  return request.post("/passport/login", { ...params, client: config.client });
}

/**
 * 账号登出
 * @returns
 */
export function apiLogout() {
  return request.post("/passport/logout");
}

/**
 * 获取账号信息
 * @returns
 */
export function apiGetMy() {
  return request.get("/administrator/my");
}

export function apiGetMyPermission() {
  return request.get("/administrator/permission");
}
