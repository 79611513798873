import Layout from "@/layouts/index.vue";

const routes = [
  {
    path: "/clue",
    meta: { title: "商机" },
    redirect: "/clue/list",
    component: Layout,
    children: [
      {
        path: "/clue/list",
        meta: {
          title: "线索列表",
          icon: "icon-jingdian",
          parentPath: "/clue",
        },
        component: () => import("@/views/clue/clue/index.vue"),
      },
    ],
  },
];

export default routes;
