import Layout from "@/layouts/index.vue";

const routes = [
  {
    path: "/",
    meta: { title: "工作台" },
    redirect: "/workbench",
    component: Layout,
    children: [
      {
        path: "/workbench",
        meta: {
          hidden: true,
          title: "工作台",
          parentPath: "/",
          permission: ["view"],
        },
        component: () => import("@/views/workbench/index.vue"),
      },
    ],
  },
];

export default routes;
