import Layout from "@/layouts/index.vue";

const routes = [
  {
    path: "/setting",
    meta: { title: "设置" },
    redirect: "/setting/system",
    component: Layout,
    children: [
      {
        path: "/setting/system",
        meta: {
          title: "系统设置",
          icon: "icon-jingdian",
          parentPath: "/setting",
        },
        component: () => import("@/views/setting/system/index.vue"),
      },
      {
        path: "/setting/mailer",
        meta: {
          title: "邮箱设置",
          icon: "icon-jingdian",
          parentPath: "/setting",
        },
        component: () => import("@/views/setting/mailer/index.vue"),
      },
    ],
  },
];

export default routes;
