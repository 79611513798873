import Layout from "@/layouts/index.vue";

const routes = [
  {
    path: "/perm",
    name: "perm",
    meta: { title: "系统" },
    redirect: "/perm/administrator",
    component: Layout,
    children: [
      {
        path: "/perm/administrator",
        meta: {
          title: "管理列表",
          icon: "icon-zhanghuxinxi",
          parentPath: "/perm",
          permission: ["view"],
        },
        component: () => import("@/views/perm/administrator/index.vue"),
      },
      {
        path: "/perm/administrator_detail",
        component: () => import("@/views/perm/administrator/detail.vue"),
        meta: {
          title: "",
          parentPath: "/perm",
          hidden: true,
        },
      },
    ],
  },
];

export default routes;
