import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import router from "@/router";

// NProgress配置
NProgress.configure({ showSpinner: false });

// 登录路由
const loginUri = "/account/login";

// 默认路由
const defaultUri = "/";

// 登录白名单
const whiteList = ["/account/login"];

/** 路由前置守卫 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  to.meta?.title && (document.title = to.meta.title);

  const token = store.getters.token;
  const permission = store.getters.permission;

  if (token) {
    if (!permission.auth) {
      try {
        await store.dispatch("getMy");
        await store.dispatch("getPermission");
        /** 令牌存在时，登录页直接跳转 */
        if (to.path === loginUri) {
          next({ path: defaultUri });
        } else {
          next();
        }
      } catch (error) {
        await store.dispatch("logout");
        next({ path: loginUri, query: { redirect: to.fullPath } });
        NProgress.done();
      }
    } else {
      next();
    }
  } else if (whiteList.includes(to.path.toLowerCase())) {
    /** 放行白名单 */
    next();
  } else {
    /** 定向登录页 */
    next({ path: loginUri, query: { redirect: to.fullPath } });
  }
});

/** 路由后置守卫 */
router.afterEach(() => {
  NProgress.done();
});
